import React from "react"
import SEO from "../components/utils/SEO"
import Layout from "../components/layout/Layout"
import Clip1 from "../videos/SichtWechselClip1pur.mp4";
import Clip1Poster from "../videos/SichtWechselClip1pur.jpg";
import Clip2 from "../videos/SichtWechselClip2pur.mp4";
import Clip2Poster from "../videos/SichtWechselClip2pur.jpg";

const FilmPage = () => (
  <Layout>
    <SEO title="Film" />

    <div className="pt-5 header">
      <h1>Film und Trailer</h1>
      <h2>Unsere aktuellen Trailer zum Anschauen</h2>
    </div>

    <h3>50 Jahre Invalidenversicherung</h3>

    <p>Zum 50. Geburtstag 2010 der IV wurde eine grosse Ausstellung organisiert.</p>
    <p>Die Ausstellung zeigte den Umgang mit Menschen mit Behinderungen.</p>
    <p>Von früher bis heute. Was ihre Probleme sind und was sie sich wünschen.</p>
    <p>Neben der Ausstellung gab es ein Programm. Dieses Programm haben verschiedene Organisationen aus Liechtenstein gestaltet.</p>
    <p>Zum Beispiel das <i>HPZ</i> und der <i>LBV</i>, der Gehörlosen Kulturverein und viele andere.</p>
    <p>Es gab einen Rollstuhlparcours, eine Einführung in Gebärdensprache, ein Kontaktcafé, eine Dunkelbar und vieles mehr.</p>

    <h3>Unsere Trailer im Skino</h3>

    <p>Damit &laquo;sichtwechsel&raquo; bekannter wird, haben wir zwei Trailer gemacht.</p>
    <p>Ein Trailer ist ein ganz kurzer Film, der vor einem Film im Kino gezeigt wird.</p>
    <p>Arno Öhri hat die beiden Trailer gemacht.</p>
    <p>Dazu hat er verschiedene Menschen fotografiert.</p>
    <p>Die Trailer können hier angeschaut werden.</p>
    <p>Sie laufen zurzeit auch im Skino in Schaan.</p>

    <div className="embed-responsive embed-responsive-16by9">
      <video controls poster={Clip1Poster}>
        <source src={Clip1} type="video/mp4" />
      </video>
    </div>

    <div className="embed-responsive embed-responsive-16by9">
      <video controls poster={Clip2Poster}>
        <source src={Clip2} type="video/mp4" />
      </video>
    </div>

  </Layout>
)

export default FilmPage
